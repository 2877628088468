/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-cascader-picker-rtl .ant-cascader-input.ant-input {
  padding-right: 11px;
  padding-left: 24px;
  text-align: right;
}
.ant-cascader-picker-rtl .ant-cascader-picker-label {
  padding: 0 12px 0 20px;
  text-align: right;
}
.ant-cascader-picker-rtl .ant-cascader-picker-clear {
  right: auto;
  left: 12px;
}
.ant-cascader-picker-rtl .ant-cascader-picker-arrow {
  right: auto;
  left: 12px;
}
.ant-cascader-picker-rtl.ant-cascader-picker-small .ant-cascader-picker-clear,
.ant-cascader-picker-rtl.ant-cascader-picker-small .ant-cascader-picker-arrow {
  right: auto;
  left: 8px;
}
.ant-cascader-menu-rtl {
  direction: rtl;
  border-right: none;
  border-left: 1px solid #f0f0f0;
}
.ant-cascader-menu-rtl:last-child {
  margin-right: 0;
  margin-left: -1px;
  border-left-color: transparent;
  border-radius: 0 0 4px 4px;
}
.ant-cascader-menu-rtl .ant-cascader-menu-item-expand {
  padding-right: 12px;
  padding-left: 24px;
}
.ant-cascader-menu-rtl .ant-cascader-menu-item-expand .ant-cascader-menu-item-expand-icon,
.ant-cascader-menu-rtl .ant-cascader-menu-item-loading-icon {
  right: auto;
  left: 12px;
}
