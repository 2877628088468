@import '../../style/themes/index';
@import '../../style/mixins/index';

@pageheader-prefix-cls: ~'@{ant-prefix}-page-header';

.@{pageheader-prefix-cls} {
  .reset-component;
  position: relative;
  padding: @page-header-padding-vertical @page-header-padding;
  background-color: @component-background;

  &-rtl {
    direction: rtl;
  }

  &-ghost {
    background-color: @page-header-ghost-bg;
  }

  &.has-breadcrumb {
    padding-top: @page-header-padding-breadcrumb;
  }

  &.has-footer {
    padding-bottom: 0;
  }

  &-back {
    float: left;
    margin: 8px 0;
    margin-right: 16px;
    font-size: 16px;
    line-height: 1;

    .@{pageheader-prefix-cls}-rtl & {
      float: right;
      margin-right: 0;
      margin-left: 16px;
    }

    &-button {
      .operation-unit();
      color: @page-header-back-color;
      cursor: pointer;
    }
  }

  .@{ant-prefix}-divider-vertical {
    height: 14px;
    margin: 0 12px;
    vertical-align: middle;
  }

  .@{ant-prefix}-breadcrumb + &-heading {
    margin-top: 8px;
  }

  &-heading {
    width: 100%;
    .clearfix;

    &-title {
      display: block;
      float: left;
      margin-bottom: 0;
      padding-right: 12px;
      color: @heading-color;
      font-weight: 600;
      font-size: @heading-4-size;
      line-height: 32px;

      .@{pageheader-prefix-cls}-rtl & {
        float: right;
        padding-right: 0;
        padding-left: 12px;
      }
    }

    .@{ant-prefix}-avatar {
      float: left;
      margin-right: 12px;

      .@{pageheader-prefix-cls}-rtl & {
        float: right;
        margin-right: 0;
        margin-left: 12px;
      }
    }

    &-sub-title {
      float: left;
      margin: 5px 0;
      margin-right: 12px;
      color: @text-color-secondary;
      font-size: 14px;
      line-height: 22px;

      .@{pageheader-prefix-cls}-rtl & {
        float: right;
        margin-right: 0;
        margin-left: 12px;
      }
    }

    &-tags {
      float: left;
      margin: 4px 0;

      .@{pageheader-prefix-cls}-rtl & {
        float: right;
      }
    }

    &-extra {
      float: right;

      .@{pageheader-prefix-cls}-rtl & {
        float: left;
      }

      > * {
        margin-left: 8px;

        .@{pageheader-prefix-cls}-rtl & {
          margin-right: 8px;
          margin-left: 0;
        }
      }
      > *:first-child {
        margin-left: 0;

        .@{pageheader-prefix-cls}-rtl & {
          margin-right: 0;
        }
      }
    }
  }

  &-content {
    padding-top: 12px;
  }

  &-footer {
    margin-top: 16px;
    .@{ant-prefix}-tabs-bar {
      margin-bottom: 1px;
      border-bottom: 0;
      .@{ant-prefix}-tabs-nav {
        .@{pageheader-prefix-cls}-rtl & {
          float: right;
        }

        .@{ant-prefix}-tabs-tab {
          padding: @tabs-horizontal-padding-sm;
          font-size: 16px;
        }
      }
    }
  }

  @media (max-width: @screen-md) {
    &-heading {
      &-extra {
        display: block;
        float: unset;
        clear: both;
        width: 100%;
        padding-top: 12px;
      }
    }
  }
}
